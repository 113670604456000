import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Hero from "../../components/hero";
import ContactPerson from "../../components/contactPerson";
import JobItem from "../../components/jobItem";
import { GatsbyImage } from "gatsby-plugin-image";
// svgs

import deliver from "../../assets/images/doodle-deliver.svg";

const Jobs = ({ data }) => {
  return (
    <Layout lang="en" langPath="nav.jobs.link">
      <Seo
        title="Find the job that suits you at Monday"
        description="At Monday you can expect more than just bits and bytes: a strong team of experts, state-of-the-art developer tools and relaxed bosses."
        keywords="Jobs, job posting, application, Java, developer, software, frontend, CoreMedia, consultant, IT, project management, system administrator, controlling"
      />
      <Hero
        headline="Your new job at Monday"
        subline="Monday gives your creative skills the space they need to grow. So what are you waiting for? Apply today!"
        section="Jobs"
      >
        <GatsbyImage
          image={data.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Jobs"
        />
      </Hero>
      <div className="container mb-4 mb-md-6">
        <div className="row">
          <div className="col-12 col-md-5 bg-size-contain bg-shape-cross mb-3 mb-md-0">
            <img
              className="w-100 mx-auto"
              src={deliver}
              alt="We offer the tools for your CMS platform"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2>More than bits and bytes</h2>
            <p>
              At Monday, you’ll work with the very latest developer tools,
              supported by experienced colleagues and a friendly management
              team.
              <br />
              You can contribute your know-how, take responsibility and develop
              professionally all at the same time.
            </p>
            <p>
              While satisfied customers are important, we know that the whole
              team has to take pride in their work. Welcome!
            </p>
            <Link
              to="/en/culture"
              className="btn bg-monday-skyblue text-color-white text-color-hover-monday-skyblue"
            >
              More about our culture
            </Link>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-md-center text-size-3 text-size-md-2">
            Current vacancies
          </h2>
        </div>
        <div className="row jobs" id="job-positions">
          {data.jobs.edges.map(({ node }) => (
            <JobItem
              key={node.id}
              headline={node.frontmatter.headline}
              paragraph={node.frontmatter.teaser}
              linkTo={node.frontmatter.slug}
            />
          ))}
        </div>
      </div>
      <ContactPerson
        headline="We look forward to hearing from you."
        text="Got what it takes? Likeable and easy-going? Know how customers tick? Perfect. Then we need to talk. These are just the qualities we’re looking for in our team."
        contactEmail="jobs@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Jannik Bischof, Lead Recruiter"
      >
        <GatsbyImage
          image={data.jannik.childImageSharp.gatsbyImageData}
          alt="Jannik Bischof"
        />
      </ContactPerson>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    jobs: allMdx(
      sort: { fields: frontmatter___priority, order: ASC }
      filter: {
        fields: { collection: { eq: "jobs" }, lang: { eq: "en" } }
        frontmatter: { inactive: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            headline
            slug
            teaser
          }
        }
      }
    }
    hero: file(relativePath: { eq: "hero-jobs.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Jobs;
